<template>
  <div id="compare-segments-container" class="pt-0 compare-segments-container">
    <div>
      <v-layout
        align-center
        justify-space-between
        row
        py-2
        px-3 
        style="background: #333333; color: #fff"
      >
        <v-flex class="text-18 font-weight-bold">
          Compare your segment
        </v-flex>
        <v-flex class="text-xs-right">
          <v-btn
            outline
            small
            color="white"
            style="min-width: 60px"
            @click="closeFilter"
          >
            CLOSE
          </v-btn>
        </v-flex>
      </v-layout>
    </div>

    <div>
      <v-layout
        v-if="comparedSegments.length > 0"
        mx-3
        mt-4
        py-3
        column
        class="bg-black"
        @mouseleave="onPanelOut"
      >
        <v-flex
          v-for="(segment, index) in comparedSegments"
          :key="index"
          justify-space-around
          class="mb-2"
        >
          <Subsegments
            :enable-scroll="enableScroll"
            :answer="segment"
            :index="index"
            :grouped-length="0"
            :background-color="backgroundColor(index)"
          />
        </v-flex>
      </v-layout>
    </div>

    <v-flex my-4 class="checklist">
      <v-list class="bg-black">
        <no-ssr>
          <div v-for="(segment, index) in segments" :key="index">
            <v-layout
              justify-space-between
              mb-1 
              px-3 
              py-1 
              class="align-center answer"
            >
              <input
                :id="`compare-segments-${segment.id}`"
                :key="index"
                v-model="segment.checked"
                type="checkbox"
                :checked="segment.checked || false"
                :data-id="segment.id"
                :copy="segment.name"
                :name="`compare-segments-${segment.id}`"
                :label="segment.name"
                :value="segment.name"
                class="mr-2 text-white styled-checkbox"
                @change="onChange"
              >
              <label :for="`compare-segments-${segment.id}`">{{
                segment.name
              }}</label>
            </v-layout>
          </div>
        </no-ssr>
      </v-list>
    </v-flex>

    <div>
      <v-alert
        id="my-alert"
        v-model="alert"
        icon="info"
        outline
        style="font-size: 13px; border: none !important"
        class="my-alert font-weight-bold ma-0"
      >
        Warning: You can only select up to 5 segments to compare
      </v-alert>
    </div>

    <div class="text-xs-left pr-3">
      <v-btn
        class="text-black"
        color="primary"
        :class="{ disabled: !enabled }"
        @click="compare"
      >
        COMPARE SELECTED SEGMENTS
      </v-btn>
    </div>

    <FilterProgress
      class="compare-segments-loader"
      :value="checkedSegmentCount"
      :total="activeSegmentCount"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import FilterProgress from '@/components/pages/survey/filters/FilterProgress'
import Subsegments from '@/components/pages/survey/filters/Subsegments'
import colorGenerator from '@/js/colorGenerator'

export default {
  components: {
    FilterProgress,
    Subsegments
  },
  data() {
    return {
      alert: false,
      enabled: false,
      segments: []
    }
  },
  computed: {
    ...mapGetters({
      survey: 'analyzeSurvey/getSurvey',
      audienceList: 'analyzeSurvey/getAudienceList',
      currentView: 'analyzeSurvey/getCurrentView',
      section: 'section',
      compareSegmentsPanelData: 'analyzeSurvey/getCompareSegmentsPanelData',
      compareSegmentsIds: 'analyzeSurvey/getCompareSegmentsIds',
      analyzeJourneyView: 'analyzeSurvey/getAnalyzeJourneyView'
    }),
    enableScroll() {
      return this.analyzeJourneyView === 'detail'
    },
    checkedCount() {
      return this.segments.reduce(
        (prev, curr) => prev + (curr.checked === true),
        0
      )
    },
    checkedSegments() {
      return this.segments.filter(segment => segment.checked === true)
    },
    comparedSegments() {
      return this.compareSegmentsPanelData.SegmentGroups
    },
    checkedSegmentCount() {
      return this.compareSegmentsPanelData.checkedSegmentCount
    },
    activeSegmentCount() {
      return this.compareSegmentsPanelData.activeSegmentCount
    }
  },
  watch: {
    audienceList(val) {
      // retrieve audience list and set default checked
      if (this.segments.length === 0) {
        this.segments = val
          .map(audience => {
            return {
              ...audience,
              checked: false
            }
          })
          .reverse()
      }
      // if new audience created keep checked state and add new audience
      else {
        const oldGroupIds = this.segments.map(segment => segment.id)
        const newAudience = val.filter(
          audience => !oldGroupIds.includes(audience.id)
        )

        if (newAudience.length > 0) {
          newAudience.reverse().forEach(audience => {
            this.segments.unshift({
              ...audience,
              checked: false
            })
          })
        }
      }
    },
    currentView(val, old) {
      if (val === 'COMPARE' && this.comparedSegments.length > 0) this.compare()
    }
  },
  mounted() {
    this.setCompareSegmentsIds([])
    this.setCompareSegmentsPanelData({
      SegmentGroups: [],
      activeSegmentCount: 0,
      checkedSegmentCount: 0
    })
  },
  methods: {
    ...mapActions({
      setView: 'analyzeSurvey/setCurrentView',
      setCompareSegmentsIds: 'analyzeSurvey/setCompareSegmentsIds',
      setCompareSegmentsPanelData: 'analyzeSurvey/setCompareSegmentsPanelData'
    }),
    compare() {
      const groupIds = this.checkedSegments.map(segment => segment.id)
      this.setCompareSegmentsIds(groupIds)

      this.$bus.$emit('compare-segments', groupIds)
      this.onChange()
    },
    onChange() {
      const groupIds = this.checkedSegments.map(segment => segment.id)

      if (
        this.checkedCount > 1 &&
        this.checkedCount <= 5 &&
        !this.arrayEquals(groupIds, this.compareSegmentsIds)
      )
        this.enabled = true
      else this.enabled = false

      if (this.checkedCount > 5) this.alert = true
      else this.alert = false
    },
    closeFilter() {
      this.setView('FILTERS')

      this.setCompareSegmentsIds([])
      this.setCompareSegmentsPanelData({
        SegmentGroups: [],
        activeSegmentCount: 0,
        checkedSegmentCount: 0
      })

      /* ignore and reset checked segments change happened after the compare-segments event.
        everytime we close panel we want to sync checked segments so that it maintains the state when re-open */
      this.segments = this.segments.map(segment => {
        return {
          ...segment,
          checked: !!this.compareSegmentsIds.includes(segment.id)
        }
      })

      // if (this.comparedSegments.length > 0) {
      this.$bus.$emit('compare-segments-closed')
      // }

      /* ignore and reset checked segments change happened after the compare-segments event.
        everytime we close panel we want to sync checked segments so that it maintains the state when re-open */
      this.segments = this.segments.map(segment => {
        return {
          ...segment,
          checked: !!this.compareSegmentsIds.includes(segment.id)
        }
      })

      if (this.comparedSegments.length > 0) {
        this.$bus.$emit('compare-segments-closed')
      }
    },
    backgroundColor(ind) {
      return colorGenerator(ind)
    },
    onPanelOut() {
      document.querySelectorAll('.rolloverbtn').forEach(ref => {
        ref.classList.remove('inactive')
      })
    },
    arrayEquals(a, b) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      )
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/_segmentFilters.scss';
@import '@/assets/css/_filterAlert.scss';
@import '@/assets/css/_custom_checkbox.scss';
@import '@/assets/css/_vars.scss';

.compare-segments-container {
  .theme--dark.v-icon {
    color: #fff !important;
  }
  .accent--text {
    color: white !important;
    caret-color: white !important;
  }
  .close-btn .v-btn__content {
    align-items: flex-end;
    justify-content: flex-end;
  }

  &#compare-segments-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);

    .checklist {
      overflow-y: scroll;
    }
  }
}
</style>
